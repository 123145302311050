export const setDefaultTab = permissions => {
    if (permissions.canViewBenefitTypesTab) {
        return 0;
    }
    if (permissions.canViewNonFlexibleBenefitTypesTab) {
        return 1;
    }
    if (permissions.canViewOffersTab) {
        return 2;
    }
    if (permissions.canViewDiscountsTab) {
        return 3;
    }
    if (permissions.canViewRolesTab) {
        return 4;
    }
    if (permissions.canViewUsersTab) {
        return 5;
    }

    return null;
};

export const shouldDisableTab = (label, permissions) => {
    if (!permissions.canViewAdminPage) {
        return true;
    }

    if (label == 'Flexible Benefits') {
        return !permissions.canViewBenefitTypesTab;
    }

    if (label == 'Non-Flexible Benefits') {
        return !permissions.canViewNonFlexibleBenefitTypesTab;
    }

    if (label == 'Offers') {
        return !permissions.canViewOffersTab;
    }
    if (label == 'Discounts') {
        return !permissions.canViewDiscountsTab;
    }

    if (label == 'Roles') {
        return !permissions.canViewRolesTab;
    }

    if (label == 'Users') {
        return !permissions.canViewUsersTab;
    }

    if (['Resources', 'Positions', 'Locations'].some(x => x === label)) {
        return !permissions.canViewResourcesTab;
    }

    return true;
};
