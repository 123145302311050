import { useEffect, useState } from 'react';

import { handleSubmit } from 'admin/utils/shared/locationsHelper';
import SubsetTabSearch from 'people/components/Dashboard/PeopleSubsetTab/SubsetTabSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    createLocation,
    fetchLocations,
    updateSearchPhrase,
    resetNotificationState,
    changeOrder,
    resetFilters,
    fetchSingleLocation,
    updateLocation,
    resetLocation
} from 'redux/slices/location';
import { CustomButton } from 'shared/components';
import Footer from 'shared/components/layout/Footer';
import { successNotification } from 'shared/components/Notifications';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import { StyledLocationsSubtab } from './styled/LocationsSubtab.styled';
import { LocationsTable } from './Table/LocationsTable';
import { LocationModal } from '../shared/LocationModal/LocationModal';

export const LocationsSubtab = ({ handleCreateColumns, permissions }) => {
    const dispatch = useDispatch();

    const {
        locations,
        searchPhrase,
        isLoading,
        totalCount,
        isAddedSuccessfully,
        isEditedSuccessfully,
        sortBy,
        sortAsc,
        locationsTotalCount,
        isEditMode
    } = useSelector(state => state.location);

    useEffect(() => {
        if (isEditedSuccessfully) {
            successNotification('Successfully updated the Location.');
        } else if (isAddedSuccessfully) {
            successNotification('Successfully added a Location.');
        }

        dispatch(resetNotificationState());
    }, [isAddedSuccessfully, isEditedSuccessfully]);

    const [sortState, setSortState] = useState([
        { id: 'updatedOn', desc: true }
    ]);

    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
    const [editModalLocationId, setEditModalLocationId] = useState(undefined);
    const [fetchLocationForEdit, setFetchLocationForEdit] = useState(false);

    useEffect(() => {
        if (editModalLocationId && fetchLocationForEdit) {
            dispatch(fetchSingleLocation(editModalLocationId));
            setIsLocationModalOpen(true);
            setFetchLocationForEdit(false);
        }
    }, [fetchLocationForEdit]);

    useEffect(() => {
        dispatch(fetchLocations(false));
    }, [searchPhrase, sortBy, sortAsc]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, []);

    const handleSortUpdate = value => {
        setSortState(value);
        dispatch(changeOrder(value));
        dispatch(resetLocation());
    };

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            dispatch(fetchLocations(true));
        },
        isLoading,
        hasMore: Boolean(locations.length < locationsTotalCount)
    });

    const handleValueUpdate = (_, searchPhrase) => {
        dispatch(updateSearchPhrase(searchPhrase));
    };

    const handleLocationModalOpen = (isOpen, locationId) => {
        if (isOpen && locationId) {
            setEditModalLocationId(locationId);
            setFetchLocationForEdit(true);
            return;
        }

        setIsLocationModalOpen(isOpen);
        setEditModalLocationId(undefined);
        dispatch(resetLocation());
    };

    return (
        <>
            <StyledLocationsSubtab>
                <div className="locations-container">
                    <div className="locations-extra-header">
                        <SubsetTabSearch
                            search={{ handleValueUpdate }}
                            allowLettersOnly={false}
                        />
                        <CustomButton
                            type={'normal'}
                            text={'Add Location'}
                            onClick={() => handleLocationModalOpen(true)}
                            disabled={!permissions.canAddResources}
                        />
                    </div>
                    <LocationsTable
                        totalCount={totalCount}
                        handleCreateTableColumns={() =>
                            handleCreateColumns(
                                handleLocationModalOpen,
                                permissions
                            )
                        }
                        locationsData={locations}
                        pageSize={10}
                        loading={isLoading}
                        noDataText={'No Locations Found'}
                        hasPagination={false}
                        sorted={sortState}
                        handleSortUpdate={handleSortUpdate}
                        boundaryRef={boundaryRef}
                        permissions={permissions}
                    />

                    <LocationModal
                        title={isEditMode ? 'Edit Location' : 'Add Location'}
                        visible={isLocationModalOpen}
                        setVisible={handleLocationModalOpen}
                        handleSubmit={formikValues =>
                            handleSubmit(
                                editModalLocationId,
                                formikValues,
                                dispatch,
                                createLocation,
                                updateLocation,
                                handleLocationModalOpen
                            )
                        }
                    />
                </div>
            </StyledLocationsSubtab>
            {!Boolean(locations.length < totalCount) && (
                <Footer className={'special'} />
            )}
        </>
    );
};
