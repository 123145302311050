/* istanbul ignore file */
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION_ONE;
const BASE_API_URL_FULL = BASE_API_URL + API_VERSION;

export default {
    ACCOUNT: BASE_API_URL_FULL + '/Account',
    CONFIRM_USER_LOGIN_MICROSOFT: function () {
        return `${this.ACCOUNT}/microsoft`;
    },
    //Employees
    PEOPLE: BASE_API_URL_FULL + '/Person',
    GET_ALL_PEOPLE: function () {
        return `${this.PEOPLE}/get-paged`;
    },
    GET_ALL_PEOPLE_PAGINATED: function (baseParams) {
        return `${this.PEOPLE}/get-paged?${baseParams}`;
    },
    GET_ALL_PEOPLE_WITH_BENEFITS: function () {
        return `${this.PEOPLE}/get-with-benefits-paged`;
    },
    GET_ALL_PEOPLE_WITH_BENEFITS_PAGINATED: function (baseParams) {
        return `${this.PEOPLE}/get-with-benefits-paged?${baseParams}`;
    },
    GET_SINGLE_PERSON: function (id) {
        return `${this.PEOPLE}/${id}/get-summary`;
    },
    GET_PERSON_CATEGORIES_COUNTS: function () {
        return `${this.PEOPLE}/category/count`;
    },
    GET_BENEFITS_CATEGORIES_COUNTS: function () {
        return `${this.PEOPLE}/benefits-category/count`;
    },
    GET_PERSONAL_INFORMATION: function (personId) {
        return `${this.PEOPLE}/${personId}/personal-information`;
    },
    CREATE_PERSON: function () {
        return `${this.PEOPLE}`;
    },
    UPDATE_PERSON: function (id) {
        return `${this.PEOPLE}/${id}`;
    },
    UPDATE_PERSONAL_INFORMATION: function (personId) {
        return `${this.PEOPLE}/${personId}/personal-information`;
    },
    DELETE_PERSON: function (id) {
        return `${this.PEOPLE}/${id}`;
    },
    GLOBAL_SEARCH: function (searchText) {
        return `${this.PEOPLE}/search?searchText=${searchText}`;
    },
    CHECK_PERSON_BY_NAME_EXISTS: function () {
        return `${this.PEOPLE}/exists`;
    },
    GET_CURRENT_COUNTER: function () {
        return `${this.PEOPLE}/counters`;
    },
    GET_ORG_CHART_BG: function () {
        return `${this.PEOPLE}/org-chart-bg`;
    },
    GET_ORG_CHART_US: function () {
        return `${this.PEOPLE}/org-chart-us`;
    },

    GET_EXCEL: function (baseParams) {
        return `${this.PEOPLE}/export-to-excel?${baseParams}`;
    },

    // History
    HISTORY: BASE_API_URL_FULL + '/History',
    GET_HISTORICAL_DATA: function (baseParams) {
        return `${this.HISTORY}/${baseParams}`;
    },
    EDIT_PERSON_HISTORICAL_DATA: function (personHistoryId) {
        return `${this.HISTORY}/person/${personHistoryId}`;
    },
    DELETE_PERSON_HISTORICAL_DATA: function (personHistoryId) {
        return `${this.HISTORY}/person/${personHistoryId}`;
    },
    EDIT_WORKING_PERIOD_HISTORICAL_DATA: function (workingPeriodHistoryId) {
        return `${this.HISTORY}/working-period/${workingPeriodHistoryId}`;
    },
    DELETE_WORKING_PERIOD_HISTORICAL_DATA: function (workingPeriodHistoryId) {
        return `${this.HISTORY}/working-period/${workingPeriodHistoryId}`;
    },
    EDIT_MANY_PERSON_HISTORICAL_DATA: function () {
        return `${this.HISTORY}/person/update-many`;
    },
    EDIT_MANY_WORKING_PERIOD_HISTORICAL_DATA: function () {
        return `${this.HISTORY}/working-period/update-many`;
    },

    // Dropdown
    DROPDOWN_DATA: BASE_API_URL_FULL + '/DropdownData',
    GET_ALL_DASHBOARD_DATA: function () {
        return `${this.DROPDOWN_DATA}/people-search`;
    },
    GET_ALL_POSITIONS: function () {
        return `${this.DROPDOWN_DATA}/positions`;
    },
    GET_ALL_MANAGERS: function () {
        return `${this.DROPDOWN_DATA}/managers`;
    },
    GET_ALL_LEVELS: function () {
        return `${this.DROPDOWN_DATA}/levels`;
    },
    GET_ALL_DEPARTMENTS: function () {
        return `${this.DROPDOWN_DATA}/departments`;
    },
    GET_ALL_INDUSTRIES: function () {
        return `${this.DROPDOWN_DATA}/industries`;
    },
    GET_ALL_LOCATIONS: function () {
        return `${this.DROPDOWN_DATA}/locations`;
    },
    GET_PEOPLE_LOCATIONS: function () {
        return `${this.DROPDOWN_DATA}/people/locations`;
    },
    GET_DISCOUNT_LOCATIONS: function () {
        return `${this.DROPDOWN_DATA}/discount/locations`;
    },
    GET_ALL_HIRING_CHANNELS: function () {
        return `${this.DROPDOWN_DATA}/hiring-channels`;
    },
    GET_ALL_VOLUNTARY_TYPES: function () {
        return `${this.DROPDOWN_DATA}/voluntary-types`;
    },
    GET_ALL_LEAVER_TYPES: function () {
        return `${this.DROPDOWN_DATA}/leaver-types`;
    },
    GET_ALL_REASON_FOR_LEAVE: function () {
        return `${this.DROPDOWN_DATA}/reasons-for-leave`;
    },
    GET_ALL_TYPES_OF_WORK: function () {
        return `${this.DROPDOWN_DATA}/types-of-work`;
    },
    GET_ALL_WORKSPLACE_TYPES: function () {
        return `${this.DROPDOWN_DATA}/workplace-types`;
    },
    GET_ALL_CLOTH_SIZES: function () {
        return `${this.DROPDOWN_DATA}/cloth-sizes`;
    },
    GET_REPORTS_TO: function () {
        return `${this.DROPDOWN_DATA}/reports-to`;
    },
    GET_WORK_EMAILS: function () {
        return `${this.DROPDOWN_DATA}/work-emails`;
    },
    GET_ALL_BENEFIT_TYPES: function (includePast) {
        return `${this.DROPDOWN_DATA}/benefit-types?includePast=${includePast}`;
    },
    GET_ALL_BENEFIT_TYPE_NAMES: function () {
        return `${this.DROPDOWN_DATA}/benefit-type-names`;
    },
    GET_ALL_DOCUMENT_TYPES: function () {
        return `${this.DROPDOWN_DATA}/document-types`;
    },
    GET_ALL_RELATION_TYPES: function () {
        return `${this.DROPDOWN_DATA}/relation-types`;
    },
    GET_ALL_ORG_CHART_ROLES: function () {
        return `${this.DROPDOWN_DATA}/org-chart-roles`;
    },
    GET_ALL_BENEFITS_REPORTS: function () {
        return `${this.DROPDOWN_DATA}/benefit-reports`;
    },
    GET_ALL_RELATIVE_MEMBERS: function (personId) {
        return `${this.DROPDOWN_DATA}/relative-members?personId=${personId}`;
    },
    GET_ALL_PERMISSIONS: function () {
        return `${this.DROPDOWN_DATA}/permissions`;
    },
    GET_ALL_ROLES: function () {
        return `${this.DROPDOWN_DATA}/roles`;
    },
    GET_BENEFICIARIES: function () {
        return `${this.DROPDOWN_DATA}/beneficiaries`;
    },
    GET_ALL_BENEFIT_REQUEST_STATUSES: function () {
        return `${this.DROPDOWN_DATA}/benefit-request-statuses`;
    },
    GET_ALL_BENEFIT_REQUEST_TYPES: function () {
        return `${this.DROPDOWN_DATA}/benefit-request-types`;
    },
    GET_BENEFIT_COVERED_BY: function () {
        return `${this.DROPDOWN_DATA}/covered-by`;
    },

    GET_BENEFIT_PRICE_GROUPS: function () {
        return `${this.DROPDOWN_DATA}/benefit-price-groups`;
    },
    GET_DISCOUNT_CATEGORIES: function () {
        return `${this.DROPDOWN_DATA}/discount/categories`;
    },
    GET_OFFER_LOCATIONS: function () {
        return `${this.DROPDOWN_DATA}/offer/locations`;
    },

    //Pictures
    PICTURE: BASE_API_URL_FULL + '/Picture',
    UPLOAD_PICTURE: function () {
        return `${this.PICTURE}`;
    },

    // Positions
    POSITION: BASE_API_URL_FULL + '/Position',
    GET_ALL_POSITIONS_PAGINATED: function () {
        return `${this.POSITION}/get-paged`;
    },
    GET_POSITION_BY_ID: function (id) {
        return `${this.POSITION}/${id}`;
    },
    CREATE_POSITION: function () {
        return `${this.POSITION}`;
    },
    UPDATE_POSITION: function (positionId) {
        return `${this.POSITION}/${positionId}`;
    },

    // Locations
    LOCATION: BASE_API_URL_FULL + '/Location',
    GET_ALL_LOCATIONS_PAGINATED: function () {
        return `${this.LOCATION}/get-paged`;
    },
    GET_LOCATION_BY_ID: function (id) {
        return `${this.LOCATION}/${id}`;
    },
    CREATE_LOCATION: function () {
        return `${this.LOCATION}`;
    },
    UPDATE_LOCATION: function (id) {
        return `${this.LOCATION}/${id}`;
    },

    // Working Period
    WORKING_PERIOD: BASE_API_URL_FULL + '/WorkingPeriod',
    GET_WORKING_PERIODS_BY_PERSON_ID: function (personId) {
        return `${this.WORKING_PERIOD}/${personId}`;
    },
    CREATE_WORKING_PERIOD: function () {
        return `${this.WORKING_PERIOD}`;
    },
    UPDATE_WORKING_PERIOD: function (workingPeriodId) {
        return `${this.WORKING_PERIOD}/${workingPeriodId}`;
    },
    CREATE_END_DATE_WORKING_PERIOD: function (workingPeriodId) {
        return `${this.WORKING_PERIOD}/end-date/create-changes/${workingPeriodId}`;
    },
    DELETE_END_DATE_WORKING_PERIOD: function (workingPeriodId) {
        return `${this.WORKING_PERIOD}/end-date/delete-changes/${workingPeriodId}`;
    },
    DELETE_WORKING_PERIOD: function (workingPeriodId) {
        return `${this.WORKING_PERIOD}/${workingPeriodId}`;
    },

    // Relative members
    RELATIVE_MEMBER: BASE_API_URL_FULL + '/RelativeMember',
    GET_RELATIVE_MEMBERS_BY_PERSON_ID: function (personId) {
        return `${this.RELATIVE_MEMBER}/${personId}`;
    },
    CREATE_RELATIVE_MEMBER_BY_PERSON_ID: function (personId) {
        return `${this.RELATIVE_MEMBER}/${personId}`;
    },
    UPDATE_RELATIVE_MEMBER: function (relativeMemberId) {
        return `${this.RELATIVE_MEMBER}/${relativeMemberId}`;
    },
    DELETE_RELATIVE_MEMBER: function (relativeMemberId) {
        return `${this.RELATIVE_MEMBER}/${relativeMemberId}`;
    },

    // Reports
    REPORT: BASE_API_URL_FULL + '/Report',
    GET_EMBED_REPORT_DATA: function (reportCategory) {
        return `${this.REPORT}/embed-report?ReportCategory=${reportCategory}`;
    },
    GET_BENEFIT_REPORT: function () {
        return `${this.REPORT}/benefit`;
    },

    // Benefits
    BENEFIT: BASE_API_URL_FULL + '/Benefit',
    CREATE_BENEFIT: function () {
        return `${this.BENEFIT}/`;
    },
    GET_ALL_BENEFITS_BY_PERSON_ID: function (personId) {
        return `${this.BENEFIT}/${personId}`;
    },
    UPDATE_BENEFIT: function (benefitId) {
        return `${this.BENEFIT}/${benefitId}`;
    },
    DELETE_BENEFIT_BY_ID: function (benefitId) {
        return `${this.BENEFIT}/${benefitId}`;
    },
    GET_EXCEL_BENEFIT: function (baseParams) {
        return `${this.BENEFIT}/export-to-excel?${baseParams}`;
    },

    //BenefitType
    BENEFIT_TYPE: BASE_API_URL_FULL + '/BenefitType',
    GET_ALL_BENEFIT_TYPES_PAGINATED: function () {
        return `${this.BENEFIT_TYPE}/get-paged`;
    },
    GET_BENEFIT_TYPE_BY_ID: function (benefitTypeId) {
        return `${this.BENEFIT_TYPE}/${benefitTypeId}`;
    },
    CREATE_BENEFIT_TYPE: function () {
        return `${this.BENEFIT_TYPE}`;
    },
    UPDATE_BENEFIT_TYPE: function (benefitTypeId) {
        return `${this.BENEFIT_TYPE}/${benefitTypeId}`;
    },
    UPDATE_BENEFIT_TYPE_VISIBILITY: function (benefitTypeId) {
        return `${this.BENEFIT_TYPE}/${benefitTypeId}/visibility`;
    },
    DELETE_BENEFIT_TYPE: function (id) {
        return `${this.BENEFIT_TYPE}/${id}`;
    },

    //Offer
    Offer: BASE_API_URL_FULL + '/Offer',
    GET_ALL_OFFERS_PAGINATED: function () {
        return `${this.Offer}/get-paged`;
    },
    GET_OFFER_BY_ID: function (offerId) {
        return `${this.Offer}/${offerId}`;
    },
    CREATE_OFFER: function () {
        return `${this.Offer}`;
    },
    UPDATE_OFFER: function (offerId) {
        return `${this.Offer}/${offerId}`;
    },
    UPDATE_OFFER_VISIBILITY: function (offerId) {
        return `${this.Offer}/${offerId}/visibility`;
    },
    DELETE_OFFER: function (id) {
        return `${this.Offer}/${id}`;
    },

    //Roles
    ROLES: BASE_API_URL_FULL + '/Role',
    GET_ALL_ROLES_PAGINATED: function () {
        return `${this.ROLES}/get-paged`;
    },
    GET_ROLE_BY_ID: function (roleId) {
        return `${this.ROLES}/${roleId}`;
    },
    CREATE_ROLE: function () {
        return `${this.ROLES}`;
    },
    UPDATE_ROLE: function (roleId) {
        return `${this.ROLES}/${roleId}`;
    },
    DELETE_ROLE: function (roleId) {
        return `${this.ROLES}/${roleId}`;
    },

    //Users
    USERS: BASE_API_URL_FULL + '/User',
    GET_ALL_USERS_PAGINATED: function () {
        return `${this.USERS}/get-paged`;
    },
    GET_USER: function (userId) {
        return `${this.USERS}/${userId}`;
    },
    CREATE_USER: function () {
        return `${this.USERS}`;
    },
    UPDATE_USER: function (userId) {
        return `${this.USERS}/${userId}`;
    },
    DELETE_USER: function (userId) {
        return `${this.USERS}/${userId}`;
    },

    //Files
    FILE: BASE_API_URL_FULL + '/File',
    UPLOAD_FILE_BY_PERSON_AND_BENEFIT_ID: function () {
        return `${this.FILE}`;
    },
    DELETE_FILE_BY_ID: function (fileId) {
        return `${this.FILE}/${fileId}`;
    },

    //On Leave
    get ON_LEAVE() {
        return this.WORKING_PERIOD + '/on-leave';
    },
    CREATE_ON_LEAVE: function (workingPeriodId) {
        return this.WORKING_PERIOD + `/${workingPeriodId}/on-leave`;
    },
    UPDATE_ON_LEAVE: function (onLeavePeriodId) {
        return this.ON_LEAVE + `/${onLeavePeriodId}`;
    },
    DELETE_ON_LEAVE: function (onLeavePeriodId) {
        return this.ON_LEAVE + `/${onLeavePeriodId}`;
    },

    //Requests
    REQUESTS: BASE_API_URL_FULL + '/Request',
    GET_REQUESTS: function () {
        return `${this.REQUESTS}`;
    },
    GET_REQUEST_COUNTS: function () {
        return `${this.REQUESTS}/count`;
    },
    UPDATE_REQUESTS: function (benefitRequestId) {
        return `${this.REQUESTS}/${benefitRequestId}`;
    },
    GET_BENEFIT_REQUEST_MESSAGES: function () {
        return `${this.REQUESTS}/benefit-request-messages`;
    },

    //Discount
    Discount: BASE_API_URL_FULL + '/Discount',
    GET_ALL_DISCOUNTS_PAGINATED: function () {
        return `${this.Discount}/get-paged`;
    },
    GET_DISCOUNT_BY_ID: function (discountId) {
        return `${this.Discount}/${discountId}`;
    },
    CREATE_DISCOUNT: function () {
        return `${this.Discount}`;
    },
    UPDATE_DISCOUNT: function (discountId) {
        return `${this.Discount}/${discountId}`;
    },
    UPDATE_DISCOUNT_VISIBILITY: function (discountId) {
        return `${this.Discount}/${discountId}/visibility`;
    },
    DELETE_DISCOUNT: function (discountId) {
        return `${this.Discount}/${discountId}`;
    }
};
