import { createSlice } from '@reduxjs/toolkit';
import positionService from 'admin/services/positionService';
import { errorNotification } from 'shared/components';

const initialState = {
    take: 10,
    sortBy: 'UpdatedOn',
    searchPhrase: null,
    sortAsc: false,

    positions: [],
    currentTotal: 0,
    positionsTotalCount: 0,

    isLoading: false,
    error: null,
    isEditMode: false,
    isAddedSuccessfully: undefined,
    isEditedSuccessfully: undefined,
    isLoadingFetchSingle: false,

    singlePositionData: {
        positionId: 0,
        title: ''
    }
};

const { reducer: positionReducer, actions } = createSlice({
    name: 'position',
    initialState,
    reducers: {
        // Fetch positions
        updateSearchPhrase: (state, action) => {
            state.searchPhrase = action.payload;
        },
        resetNotifications: state => {
            state.isAddedSuccessfully = undefined;
            state.isEditedSuccessfully = undefined;
        },
        fetchPositionsStart: state => {
            state.isLoading = true;
        },
        fetchPositionsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.positions = [...state.positions, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.positionsTotalCount = totalCount;
        },
        fetchPositionsFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        updatePositionSuccess: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
            state.isEditedSuccessfully = true;
        },
        updatePositionFailure: state => {
            state.isLoadingEdit = false;
            state.isLoading = false;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.positions = [];
            state.currentTotal = 0;
            state.positionsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        resetFilters: state => {
            state.skip = 0;
            state.take = 10;
            state.sortBy = 'UpdatedOn';
            state.sortAsc = false;
            state.searchPhrase = '';
            state.positions = [];
            state.currentTotal = 0;
            state.positionsTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        setLoadStateToActive: state => {
            state.active = true;
        },

        updatePositionStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isLoadingEdit = true;
        },

        resetShowUpdate: state => {
            state.showUpdateMessage = false;
        },
        createPositionStart: state => {
            state.isLoading = true;
            state.isLoadingCreate = true;
        },
        createPositionFailure: (state, action) => {
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.error = action.payload;
        },
        createPositionSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.isLoadingCreate = false;
            state.showCreateMessage = true;
            state.error = null;

            state.positions = [...data];
            state.currentTotal = data.length;
            state.positionsTotalCount = totalCount;
            state.isAddedSuccessfully = true;
        },

        resetPositionModal: state => {
            state.singlePositionData = initialState.singlePositionData;
            state.isEditMode = false;
        },

        fetchSinglePositionStart: state => {
            state.isLoadingFetchSingle = true;
            state.isEditMode = true;
        },

        fetchSinglePositionSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = null;
            state.singlePositionData = action.payload;
        },

        fetchSinglePositionFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = action.payload;
        },
        reloadPositionsSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.positions = data;
            state.currentTotal = data.length;
            state.positionsTotalCount = totalCount;
        }
    }
});

const fetchPositions = isScrolled => {
    return async (dispatch, getState) => {
        if (getState().position.isLoading) {
            return;
        }

        try {
            dispatch(actions.fetchPositionsStart());
            let skip = getState().position.currentTotal;
            let take = getState().position.take;

            if (!isScrolled) {
                skip = 0;
                take = 10;
                dispatch(actions.cleanState());
            }

            const { sortAsc, sortBy, searchPhrase } = getState().position;
            const resp = await positionService.getAllPositions({
                skip,
                take,
                sortBy,
                sortAsc,
                searchPhrase
            });
            const totalCount = resp.metadata.totalCount;
            const data = resp.data;
            dispatch(actions.fetchPositionsSuccess({ data, totalCount }));
        } catch (err) {
            dispatch(actions.fetchPositionsFailure(err));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const resetPositionState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetPosition = () => {
    return dispatch => {
        dispatch(actions.resetPositionModal());
    };
};

const resetShowUpdateMessage = () => {
    return dispatch => {
        dispatch(actions.resetShowUpdate());
    };
};

const fetchSinglePosition = positionId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSinglePositionStart());
            const data = await positionService.getPositionById(positionId);
            dispatch(actions.fetchSinglePositionSuccess(data.data));
        } catch (err) {
            dispatch(
                actions.fetchSinglePositionFailure('Something went wrong.')
            );
        }
    };
};

const createPosition = requestData => {
    return async (dispatch, getState) => {
        if (getState().position.isLoadingCreate) {
            return;
        }

        try {
            dispatch(actions.createPositionStart());
            await positionService.addPosition(requestData);
            const skip = 0;
            const { take } = getState().position;
            const resp = await positionService.getAllPositions({
                skip,
                take
            });
            const {
                data,
                metadata: { totalCount }
            } = resp;
            dispatch(actions.createPositionSuccess({ data, totalCount }));
        } catch (error) {
            const errorMessage =
                error.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.createPositionFailure(errorMessage));
        }
    };
};

const reloadPositions = async (dispatch, getState) => {
    const { sortAsc, sortBy, searchPhrase } = getState().position;

    const skip = 0;
    const take = 10;

    const resp = await positionService.getAllPositions({
        skip,
        take,
        sortBy,
        sortAsc,
        searchPhrase
    });
    const {
        data,
        metadata: { totalCount }
    } = resp;
    dispatch(actions.reloadPositionsSuccess({ data, totalCount }));
};

const updatePosition = (positionId, positionData) => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updatePositionStart());
            await positionService.updatePosition(positionId, positionData);
            await reloadPositions(dispatch, getState);
            await resetFilters();
            dispatch(actions.updatePositionSuccess());
            dispatch(actions.resetPositionModal());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updatePositionFailure(errorMessage));
        }
    };
};

const resetNotificationState = () => {
    return async dispatch => dispatch(actions.resetNotifications());
};

const updateSearchPhrase = searchPhrase => {
    return async dispatch => dispatch(actions.updateSearchPhrase(searchPhrase));
};

export {
    positionReducer,
    actions as positionActions,
    updateSearchPhrase,
    fetchPositions,
    changeOrder,
    resetPositionState,
    resetFilters,
    resetPosition,
    resetShowUpdateMessage,
    fetchSinglePosition,
    createPosition,
    updatePosition,
    resetNotificationState
};
