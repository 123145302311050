import React, { useEffect, useState } from 'react';

import { Upload, Switch } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetOffer,
    loadOfferLocations,
    addOffer,
    updateOffer
} from 'redux/slices/offer';
import { CustomButton } from 'shared/components/Button';
import { CloseButton } from 'shared/components/CloseButton';
import { CustomInput } from 'shared/components/Input';
import { CustomMultiSelect } from 'shared/components/MultiSelect';
import {
    successNotification,
    errorNotification
} from 'shared/components/Notifications';
import RichText from 'shared/components/RichText';
import { Spinner } from 'shared/components/Spinner';
import Row from 'shared/components/styled/Row.styled';
import { UploadImage } from 'shared/components/UploadImage';
import UploadIcon from 'shared/theme/assets/icons/UploadIcon';
import { mapDataForDropdown } from 'utils/dropdownHelper';

import {
    handleOfferLocationOnChange,
    MAX_LENGTH_RICH_TEXT,
    getSelectedLocationOnEdit,
    setClassName,
    validateOffer
} from './offerModalHelper';
import { SelectedOptions } from './SelectedOptions';
import { UploadFileModal } from './UploadFileModal';
import { StyledOfferModal } from '../OfferModal/styled/OfferModal.styled';

const FORMAT = 'MM/DD/YYYY';

const OfferModal = ({
    title,
    visible,
    setVisible = () => {},
    closeCallback = () => {}
}) => {
    const dispatch = useDispatch();
    const {
        isLoadingFetchSingle,
        offerSingleData,
        errorFetchSingle,
        locations,
        isEditMode
    } = useSelector(state => state.offer);

    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationOptions = mapDataForDropdown(locations);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const handlePreview = file => {
        if (file.isNew) {
            return;
        }

        const newWindow = window.open(
            file.url,
            '_blank',
            'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
    };

    const handleShowUploadModal = () => {
        setIsUploadModalOpen(true);
    };

    useEffect(() => {
        if (isEditMode && offerSingleData) {
            setSelectedLocations(getSelectedLocationOnEdit(offerSingleData));
        }
    }, [offerSingleData]);

    useEffect(() => {
        if (errorFetchSingle) {
            errorNotification(errorFetchSingle);
        }
    }, [errorFetchSingle]);

    const handleClose = () => {
        closeCallback();
        dispatch(setVisible(false));
    };

    useEffect(() => {
        if (visible) {
            dispatch(loadOfferLocations());
        }
    }, [visible]);

    const handleRichTextChange = (name, values, setFieldValue) => {
        setFieldValue(name, values.html);
        setFieldValue(name + '_plainText', values.plainText);
    };

    const handleRemoveFile = (file, values, setFieldValue) => {
        let newFileList = values.files.map(_file => {
            if (_file.uid === file.uid) {
                return {
                    ..._file,
                    isDeleted: true
                };
            }
            return _file;
        });

        setFieldValue('files', newFileList);
    };

    const localSubmit = (submitValues, dirty, isValid) => {
        if (dirty && isValid) {
            isEditMode
                ? dispatch(updateOffer(submitValues))
                : dispatch(addOffer(submitValues));
        }
    };

    const showUploadSuccessMessage = fileList => {
        if (fileList.length === 1) {
            successNotification('Successfully uploaded file', 5);
        } else {
            successNotification(
                'Successfully uploaded ' + fileList.length + ' files',
                5
            );
        }
    };

    const onUploadClick = (fileList, formikData, setFieldValue) => {
        let newFileList = formikData.files.map(_file => {
            if (fileList.find(f => f.name === _file.name) !== undefined) {
                return {
                    ..._file,
                    isDeleted: true
                };
            }
            return _file;
        });

        fileList.forEach(file => {
            newFileList.push({
                uid: file.uid,
                name: file.name,
                url: '',
                isNew: true,
                data: file
            });
        });

        setFieldValue('files', newFileList);
        setIsUploadModalOpen(false);
        showUploadSuccessMessage(fileList);
    };

    useEffect(() => {
        return () => {
            dispatch(resetOffer());
        };
    }, []);

    const changeActive = (checked, setFieldValue) => {
        setFieldValue('active', checked);

        if (!checked) {
            setFieldValue('selectable', false);
        }
    };

    return (
        <Formik
            initialValues={offerSingleData}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={true}
            validate={validateOffer}
        >
            {props => {
                const {
                    values,
                    errors,
                    touched,
                    isValid,
                    dirty,
                    setFieldValue,
                    setFieldTouched
                } = props;
                return (
                    <StyledOfferModal
                        visible={visible}
                        setVisible={setVisible}
                        title={[
                            <div
                                className="custom-modal-header"
                                key="modal-title"
                            >
                                {title}
                                <CloseButton handleClose={handleClose} />
                            </div>
                        ]}
                        footer={
                            !isLoadingFetchSingle && (
                                <div
                                    className="custom-modal-footer"
                                    key="modal-footer"
                                >
                                    <div className="button-section">
                                        <CustomButton
                                            text="Close"
                                            type="normal footer-button"
                                            onClick={handleClose}
                                            disabled={isLoadingFetchSingle}
                                        />
                                    </div>
                                    <div className="button-section">
                                        <CustomButton
                                            text="Save"
                                            type="filled footer-button"
                                            onClick={() =>
                                                localSubmit(
                                                    values,
                                                    dirty,
                                                    isValid
                                                )
                                            }
                                            disabled={!(dirty && isValid)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        handleCustomCancel={closeCallback}
                        data-testid="offer-type-modal"
                    >
                        {isLoadingFetchSingle ? (
                            <div className="spinner-container">
                                <Spinner />
                            </div>
                        ) : (
                            <Form>
                                <UploadImage
                                    imageText="Select Image *"
                                    onUpload={file => {
                                        setFieldValue('image', file);
                                        setFieldValue('updateImage', true);
                                        setFieldTouched('image');
                                    }}
                                    initialImage={values.image}
                                    maxSize={MAX_IMAGE_SIZE}
                                    hideDeleteButton={true}
                                    externalError={
                                        touched.image && errors?.image
                                    }
                                />
                                <CustomInput
                                    label="Image Alt Text *"
                                    placeholder="Image Alt Text..."
                                    value={values.imageAltText}
                                    name={'imageAltText'}
                                    className={setClassName(
                                        touched.imageAltText &&
                                            errors?.imageAltText
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <CustomInput
                                    label="Offer *"
                                    placeholder="Offer..."
                                    value={values.name}
                                    name={'name'}
                                    className={setClassName(
                                        touched.name && errors?.name
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <CustomInput
                                    label="Short Offer Description *"
                                    placeholder="Short Offer Description..."
                                    value={values.shortDescription}
                                    name={'shortDescription'}
                                    className={setClassName(
                                        touched.shortDescription &&
                                            errors?.shortDescription
                                    )}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    allowClear={true}
                                />
                                <div className="selectors-section">
                                    <div className="selector">
                                        <CustomMultiSelect
                                            className={`wider ${setClassName(
                                                touched.locations &&
                                                    errors?.locations
                                            )}`}
                                            label="Location *"
                                            hasSelectAll={false}
                                            disableSearch={false}
                                            canCreate={true}
                                            value={values.locations}
                                            name={'locations'}
                                            options={locationOptions}
                                            onChange={(name, _, values) =>
                                                handleOfferLocationOnChange(
                                                    name,
                                                    values,
                                                    setFieldValue,
                                                    setFieldTouched
                                                )
                                            }
                                            onClick={isMenuOpened => {
                                                setFieldTouched(
                                                    'locations',
                                                    !isMenuOpened
                                                );
                                            }}
                                            onSelection={setSelectedLocations}
                                            selected={selectedLocations}
                                        />
                                    </div>
                                </div>
                                <SelectedOptions
                                    options={selectedLocations}
                                    values={values.locations}
                                    onChange={value => {
                                        setSelectedLocations(value);
                                        handleOfferLocationOnChange(
                                            'locations',
                                            value,
                                            setFieldValue,
                                            setFieldTouched
                                        );
                                    }}
                                    handleValues={setFieldValue}
                                />
                                <CustomInput
                                    label="Website"
                                    placeholder="Website..."
                                    value={values.website}
                                    name={'website'}
                                    className={setClassName(errors?.website)}
                                    isNormalChange={false}
                                    onChange={setFieldValue}
                                    allowClear={true}
                                />
                                <RichText
                                    label="How much *"
                                    placeholder="How much ...*"
                                    value={values.howMuch}
                                    name={'howMuch'}
                                    hasError={
                                        touched.howMuch && errors?.howMuch
                                    }
                                    onChange={(name, values) =>
                                        handleRichTextChange(
                                            name,
                                            values,
                                            setFieldValue
                                        )
                                    }
                                    setFieldTouched={setFieldTouched}
                                    maxLength={MAX_LENGTH_RICH_TEXT}
                                    id={'howMuch'}
                                />
                                <RichText
                                    label="How to use *"
                                    placeholder="How to use ...*"
                                    value={values.howToUse}
                                    name={'howToUse'}
                                    hasError={
                                        touched.howToUse && errors?.howToUse
                                    }
                                    onChange={(name, values) =>
                                        handleRichTextChange(
                                            name,
                                            values,
                                            setFieldValue
                                        )
                                    }
                                    setFieldTouched={setFieldTouched}
                                    maxLength={MAX_LENGTH_RICH_TEXT}
                                    id={'howToUse'}
                                />

                                <CustomButton
                                    style={{ width: '160px' }}
                                    type="normal"
                                    iconComponent={UploadIcon}
                                    text={'Upload document'}
                                    disabled={isLoadingFetchSingle}
                                    onClick={handleShowUploadModal}
                                />
                                {values.files && (
                                    <div className="custom-upload-div">
                                        <div className="custom-upload-label">
                                            Uploaded files:{' '}
                                            {
                                                values.files.filter(
                                                    file => !file.isNew
                                                ).length
                                            }
                                        </div>
                                        <Upload
                                            fileList={values.files
                                                .filter(file => !file.isDeleted)
                                                .map(file => {
                                                    return {
                                                        ...file,
                                                        name: file.date
                                                            ? file.name +
                                                              ' (' +
                                                              moment(
                                                                  file.date
                                                              ).format(FORMAT) +
                                                              ')'
                                                            : file.name
                                                    };
                                                })}
                                            onPreview={handlePreview}
                                            showUploadList={{
                                                showRemoveIcon: true,
                                                showPreviewIcon: true
                                            }}
                                            onRemove={file =>
                                                handleRemoveFile(
                                                    file,
                                                    values,
                                                    setFieldValue
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <Row justifyContent="space-between">
                                    <span className="input-label">
                                        Make this Offer to be visible on
                                        MentorNet
                                    </span>
                                    <Row>
                                        <Switch
                                            checked={values.selectable}
                                            checkedChildren="YES"
                                            unCheckedChildren="NO"
                                            disabled={!values.active}
                                            onChange={checked => {
                                                setFieldValue(
                                                    'selectable',
                                                    checked
                                                );
                                            }}
                                        />
                                    </Row>
                                </Row>
                                <Row justifyContent="space-between">
                                    <span className="input-label">
                                        Make this Offer active in Humate
                                    </span>
                                    <Row>
                                        <Switch
                                            checked={values.active}
                                            checkedChildren="YES"
                                            unCheckedChildren="NO"
                                            onChange={checked => {
                                                changeActive(
                                                    checked,
                                                    setFieldValue
                                                );
                                            }}
                                        />
                                    </Row>
                                </Row>
                            </Form>
                        )}
                        {isUploadModalOpen && (
                            <UploadFileModal
                                title={'Upload document(s)'}
                                action={'added'}
                                isModalOpen={isUploadModalOpen}
                                setIsModalOpen={setIsUploadModalOpen}
                                formikData={values}
                                onUploadClick={fileList =>
                                    onUploadClick(
                                        fileList,
                                        values,
                                        setFieldValue
                                    )
                                }
                            />
                        )}
                    </StyledOfferModal>
                );
            }}
        </Formik>
    );
};

export { OfferModal };

const MAX_IMAGE_SIZE = 5242880; // 1024*1024*5 -> 5MB
