const TITLE_MAX_LENGTH = 100;

export const validatePositionTitle = (input, maxLength) => {
    if (!input?.trim()) {
        return 'Title is required';
    }

    if (input.trim().length > maxLength) {
        return `Title must be between 1 and ${maxLength} characters long`;
    }

    const matchesPattern = /^[A-Za-z\-\&\/\. ]*$/.test(input);

    if (!matchesPattern) {
        return 'Invalid type of characters';
    }

    return '';
};

export const validatePosition = values => {
    const errors = {};
    const positionTitleError = validatePositionTitle(
        values.title,
        TITLE_MAX_LENGTH
    );

    if (positionTitleError) {
        errors.title = positionTitleError;
    }

    return errors;
};

export const handleSubmit = (
    editModalPositionId,
    requestData,
    dispatch,
    createPosition,
    updatePosition
) => {
    const isEditMode = typeof editModalPositionId !== 'undefined';

    isEditMode
        ? dispatch(updatePosition(editModalPositionId, requestData))
        : dispatch(createPosition(requestData));
};
