import { createSlice } from '@reduxjs/toolkit';
import offerService from 'admin/services/offerService';
import { errorNotification } from 'shared/components';
import { stripHTML } from 'shared/utils/richTextHelper';

const initialState = {
    take: 10,
    sortBy: 'UpdatedOn',
    searchPhrase: null,
    sortAsc: false,
    loadActive: true,

    currentOffers: [],
    currentTotal: 0,
    currentOffersTotalCount: 0,

    isLoading: false,
    error: null,

    locations: [],

    isEditMode: false,
    isAddEditModalOpen: false,
    isAddedEditedSuccessfully: false,

    offerSingleData: {
        offerId: 0,
        name: '',
        shortDescription: '',
        howMuch: '',
        howToUse: '',
        image: null,
        imageAltText: null,
        active: false,
        selectable: false,
        website: '',
        locations: [],
        files: [],
        selectable: false
    }
};

const { reducer: offerReducer, actions } = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        // Fetch offers
        fetchOffersStart: (state, action) => {
            const { searchPhrase, loadActive } = action.payload;
            state.isLoading = true;
            state.loadActive = loadActive;
            state.searchPhrase = searchPhrase;
        },
        fetchOffersSuccess: (state, action) => {
            const { data, totalCount } = action.payload;
            state.isLoading = false;
            state.error = null;
            state.currentOffers = [...state.currentOffers, ...data];
            state.currentTotal = state.currentTotal + data.length;
            state.currentOffersTotalCount = totalCount;
        },
        fetchOffersFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        changeOrderState: (state, action) => {
            const {
                payload: { sortBy, sortAsc }
            } = action;
            state.sortBy = sortBy;
            state.sortAsc = sortAsc;
        },

        updateOfferSuccess: (state, action) => {
            state.isLoading = false;
            state.isAddEditModalOpen = false;
            const { data, totalCount, active } = action.payload;

            state.currentOffers = [...data];
            state.currentTotal = data.length;
            state.currentOffersTotalCount = totalCount;
            state.isAddedEditedSuccessfully = true;
        },
        updateOfferFailure: state => {
            state.isLoading = false;
        },

        // clear state
        cleanState: state => {
            state.take = 10;
            state.skip = 0;
            state.currentOffers = [];
            state.currentTotal = 0;
            state.currentOffersTotalCount = 0;
            state.isLoading = false;
            state.error = null;
            state.isEditMode = false;
        },

        // reset filters
        resetFilters: state => {
            state.take = 10;
            state.sortBy = 'UpdatedOn';
            state.sortAsc = false;
            state.currentOffers = [];
            state.currentTotal = 0;
            state.currentOffersTotalCount = 0;
            state.isLoading = false;
            state.error = null;
        },

        // reset edit offer modal state
        resetOfferModal: state => {
            state.offerSingleData = initialState.offerSingleData;
        },

        updateOfferStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isEditMode = true;
            state.isAddedEditedSuccessfully = false;
        },

        addOfferStart: state => {
            state.isLoading = true;
            state.editError = null;
            state.isAddedEditedSuccessfully = false;
        },

        addUpdateOfferSuccess: (state, action) => {
            state.isAddedEditedSuccessfully = true;
            state.isAddEditModalOpen = false;
        },

        addOfferFailure: state => {
            state.isLoading = false;
        },

        hideAddEditOfferModal: state => {
            state.isAddEditModalOpen = false;
        },

        showAddEditOfferModal: (state, action) => {
            state.isAddEditModalOpen = true;
            state.isEditMode = action.payload.isEditMode;
        },

        fetchSingleOfferStart: state => {
            state.isLoadingFetchSingle = true;
        },

        fetchSingleOfferSuccess: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = null;

            action.payload.locations.forEach(l => (l.isNew = false));

            action.payload.howMuch_plainText = stripHTML(
                action.payload.howMuch
            );
            action.payload.howToUse_plainText = stripHTML(
                action.payload.howToUse
            );

            state.offerSingleData = action.payload;
            state.isEditMode = true;
        },

        fetchSingleOfferFailure: (state, action) => {
            state.isLoadingFetchSingle = false;
            state.error = action.payload;
        },

        loadOfferLocations: (state, action) => {
            state.locations = action.payload;
        },
        resetNotifications: state => {
            state.isAddedEditedSuccessfully = undefined;
        }
    }
});

const reloadOffers = async (dispatch, getState) => {
    const { sortAsc, sortBy, skip, take, searchPhrase, loadActive } =
        getState().offer;
    const resp = await offerService.getAllOffers(
        { skip, take, sortBy, sortAsc, searchPhrase },
        loadActive
    );
    const {
        data,
        metadata: { totalCount }
    } = resp;
    dispatch(actions.fetchOffersSuccess({ data, totalCount }));
};

const fetchActiveOffers = (searchPhrase, isScrolled) => {
    return async (dispatch, getState) => {
        if (getState().offer.isLoading) {
            return;
        }

        try {
            dispatch(
                actions.fetchOffersStart({ searchPhrase, loadActive: true })
            );

            if (!isScrolled) {
                dispatch(actions.cleanState());
            }

            await reloadOffers(dispatch, getState);
        } catch (err) {
            dispatch(actions.fetchOffersFailure(err));
        }
    };
};

const fetchInactiveOffers = (searchPhrase, isScrolled) => {
    return async (dispatch, getState) => {
        if (getState().offer.isLoading) {
            return;
        }

        try {
            dispatch(
                actions.fetchOffersStart({ searchPhrase, loadActive: false })
            );

            if (!isScrolled) {
                dispatch(actions.cleanState());
            }

            await reloadOffers(dispatch, getState);
        } catch (err) {
            dispatch(actions.fetchOffersFailure(err));
        }
    };
};

const updateOfferVisibility = offerId => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updateOfferStart());
            await offerService.updateOfferVisibility(offerId);
            await reloadOffers(dispatch, getState);
            dispatch(actions.addUpdateOfferSuccess());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateOfferFailure(errorMessage));
        }
    };
};

const changeOrder = orderValue => {
    const { id, desc } = orderValue[0];
    const sortBy = id.charAt(0).toUpperCase() + id.slice(1);
    const sortAsc = sortBy === 'UpdatedOn' && desc === false ? true : !desc;
    return async dispatch => {
        dispatch(actions.changeOrderState({ sortBy, sortAsc }));
    };
};

const resetOfferState = () => {
    return dispatch => {
        dispatch(actions.cleanState());
    };
};

const resetFilters = () => {
    return dispatch => {
        dispatch(actions.resetFilters());
    };
};

const resetOffer = () => {
    return dispatch => {
        dispatch(actions.resetOfferModal());
    };
};

const fetchSingleOffer = offerId => {
    return async (dispatch, getState) => {
        if (getState().role.isLoadingFetchSingle) {
            return;
        }

        try {
            dispatch(actions.fetchSingleOfferStart());
            const data = await offerService.getOfferById(offerId);
            dispatch(actions.fetchSingleOfferSuccess(data.data));
        } catch (err) {
            dispatch(actions.fetchSingleOfferFailure('Something went wrong.'));
        }
    };
};

const loadOfferLocations = () => {
    return async dispatch => {
        const payload = await offerService.getAvailableLocationOptions();
        dispatch(actions.loadOfferLocations(payload.data));
    };
};

const updateOffer = offerData => {
    return async (dispatch, getState) => {
        try {
            dispatch(actions.updateOfferStart());
            await offerService.updateOffer(offerData.offerId, offerData);
            await reloadOffers(dispatch, getState);
            dispatch(actions.addUpdateOfferSuccess());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateOfferFailure(errorMessage));
        }
    };
};

const addOffer = offerData => {
    return async (dispatch, getState) => {
        if (getState().offer.isLoading) {
            return;
        }

        try {
            dispatch(actions.addOfferStart());
            await offerService.addOffer(offerData);
            await reloadOffers(dispatch, getState);
            dispatch(actions.addUpdateOfferSuccess());
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.addOfferFailure(errorMessage));
        }
    };
};

const deleteOffer = offerId => {
    return async (dispatch, getState) => {
        try {
            await offerService.deleteOffer(offerId);
            await reloadOffers(dispatch, getState);
        } catch (err) {
            const errorMessage =
                err.response?.data?.messages?.[0] || 'Something went wrong';
            errorNotification(errorMessage);
            dispatch(actions.updateOfferFailure(errorMessage));
        }
    };
};

const setAddEditOfferModalOpen = (isVisible, isEditMode) => {
    return dispatch => {
        if (isVisible) {
            dispatch(actions.showAddEditOfferModal(isEditMode));
        } else {
            dispatch(actions.hideAddEditOfferModal());
        }
    };
};

const resetNotifications = () => {
    return dispatch => {
        dispatch(actions.resetNotifications());
    };
};

export {
    offerReducer,
    actions as offerActions,
    fetchActiveOffers,
    fetchInactiveOffers,
    changeOrder,
    resetOfferState,
    resetFilters,
    updateOfferVisibility,
    resetOffer,
    fetchSingleOffer,
    loadOfferLocations,
    updateOffer,
    addOffer,
    deleteOffer,
    setAddEditOfferModalOpen,
    resetNotifications
};
